import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

function PromoBanner() {
  return (
    <>
    <div style={styles}>
    <Link to="https://t.me/smarten_tradingbot?start=ref_5456001864" target="_blank" rel="noreferrer">
    <Card  className="text-center">
      <Card.Img className='mr-4 rounded-full'  variant="top" src="Smarten-Trading-Bot-DEFIGEM-app.gif" align="center" width="1300" height="250" />
       
    </Card>
    </Link>
    </div>
    <br />
    <div style={styles}>
    <Card className="text-center">
      <Card.Img className='mr-4 rounded-full'  variant="top" src="Meta-Force-Space-DEFIGEM-Top-Team.gif" width="450" height="250" />
      <Card.Body>
        <Card.Title className='text-4xl font-bold py-4'>💎 META FORCE COIN - Join METAVERSE Space 🚀</Card.Title>
        <Card.Text className='text-1xl font-bold py-4'>
        ★ Join Meta Force with our Team and unlock your possibility of earning real passive Income. ✨ We are making real money while exploring the metaverse. Join us and discover the future of the internet with Meta Force Universe. It's more than just a game; it's a new reality! Feel free to reach out to us for more details.
        </Card.Text>
        <Button  className=' my-2 p-3 m-2 bg-button text-btnText rounded-2xl shadow-xl' href='https://meta-force.space/r/6jQ2WJPvIT' target='blank' style={{ background: "orange" }}>Official Team Link - One step to success</Button>{' '}
        <Button  className=' my-2 p-3 m-1 bg-button text-btnText rounded-2xl shadow-xl' href='https://defigem.app/MetaForceCoinExplained' >Full Article</Button>
        </Card.Body>
      
    </Card>
    </div>
    <br />
    <div style={styles}>
    <Card className="text-center">
      <Card.Img className='mr-4 rounded-full'  variant="top" src="MPIX-MEGAPIXAI.gif" width="300" height="250" />
      <Card.Body>
        <Card.Title className='text-4xl font-bold py-4'>🏆 Megapix AI - Ignite Your Imagination 🔥</Card.Title>
        <Card.Text className='text-1xl font-bold py-4'>
        An artistic journey with Megapix, where every pixel narrates your unique story. Embrace the Magic of AI for limitless possibilities! Transform & Optimize yourself with the power of AI and stay ahead of the curve! The future is AI-powered!
        </Card.Text>
        <Button  className=' my-2 p-3 bg-button text-btnText rounded-2xl shadow-xl' href='https://megapix.ai' >Explore $MPIX Space</Button>
      </Card.Body>
      
    </Card>
    </div>
    <br />
    <div style={styles}>
    <Card className="text-center">
      <Card.Img className='mr-4 rounded-full'  variant="top" src="DefiGem-app-300.gif" width="300" height="250" />
      <Card.Body>
        <Card.Title className='text-4xl font-bold py-4'>🔥 Special Ad treatment 🔥</Card.Title>
        <Card.Text className='text-1xl font-bold py-4'>
        Need to boost your marketing? You are in the right place and your ad will be shown to the right people. Driving traffic is our bread and butter and we are constantly growing.
        </Card.Text>
        <Button  className=' my-2 p-3 bg-button text-btnText rounded-2xl shadow-xl' href='https://t.me/DefiDevelope' target='blank'>Book this spot</Button>
      </Card.Body>
      
    </Card>
    </div>
    </>
    );
  }



export default PromoBanner;