import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';


const styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

function Promo2() {
  return (

    <div style={styles}>
    <Link to="https://t.me/DefiGemBot" target="_blank" rel="noreferrer">
    <Card  className="text-center">
      <Card.Img className='mr-4 rounded-full'  variant="top" src="free-crypto-token.gif" align="center" width="1300" height="250" />
       
    </Card>
    </Link>
    </div>
    );
  }

export default Promo2;