import React, { useState } from 'react'
import { AiFillLock, AiOutlineMail } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'

function SignupPage() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const { signUp } = UserAuth()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')

    const create = await signUp(email, password)
    if (!(typeof(create) === Error)) {
      console.log('user created!')
      console.log(create)
      navigate('/signin')
    } else {
    setError('Error creating user')
    console.log('Error creating user')
    }
  }

  return (
    <div>
      <div className='max-w-[400px] mx-auto min-h-[600px] px-4 py-20'>
        <h1 className='text-2xl font-bold'>Sign up</h1>
        {error ? <p className='bg-red-300 p-3 my-2'>{error}</p> : null}
        <form>
          <div className='my-4'>
            <label>Email</label>
            <div className='my-2 w-full relative rounded-2xl shadow-xl'>
              <input
                onChange={(e) => setEmail(e.target.value)} 
                className='w-full p-2 bg-primary border border-input rounded-2xl' type='email' />
              <AiOutlineMail className='absolute right-2 top-3 text-gray-400' />
            </div>
          </div>
          <div className='my-4'>
            <label>Password</label>
            <div className='my-2 w-full relative rounded-2xl shadow-xl'>
              <input 
                onChange={(e) => setPassword(e.target.value)} 
                className='w-full p-2 bg-primary border border-input rounded-2xl' type='password' />
              <AiFillLock className='absolute right-2 top-3 text-gray-400'/>
            </div>
          </div>
          <button onClick={handleSubmit} className='w-full my-2 p-3 bg-button text-btnText rounded-2xl shadow-xl'>
            Sign up
          </button>
        </form>
        <p className='my-4'>
          Already have an account? <Link className='text-accent' to='/signin'>Sign in</Link>
        </p>
      </div>
    </div>
  )
}

export default SignupPage