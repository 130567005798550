import React from 'react';
import { FaTelegram, FaTwitter } from 'react-icons/fa';
import ThemeToggle from './ThemeToggle';

function Footer() {
  return (
    <div className='rounded-div mt-8 pt-8 text-primary'>
      <div className='flex flex-col md:w-[70%] m-auto'>
        <div className='flex flex-row justify-between items-center'>
          <div className='w-[50%]'>
            <div>
              <p className='text-center'>DEFI GEM Socials</p>
            </div>
            <div className='flex py-4 justify-between text-accent w-40 m-auto'>
              <FaTwitter
                onClick={() => (
                  window.open("https://twitter.com/DEFIGEMapp", '_blank', 'noopener,noreferrer')
                )}
                size={30} className='cursor-pointer hover:scale-105 duration-200'
              />
              <FaTelegram
                onClick={() => (
                  window.open("https://t.me/DefiGemApp", '_blank', 'noopener,noreferrer')
                )}
                size={30} className='cursor-pointer hover:scale-105 duration-200'
              />
              <FaTelegram
                onClick={() => (
                  window.open("https://t.me/DefiGemBot", '_blank', 'noopener,noreferrer')
                )}
                size={30} className='cursor-pointer hover:scale-105 duration-200'
              />
            </div>
          </div>
          <div className='m-auto mb-8'>
            <ThemeToggle />
          </div>
        </div>
        <div className='border-t w-[80%] m-auto'>
          <a href='https://defi-gem.gitbook.io/disclaimers' target='_blank' rel='noreferrer'>
            <p className='text-center py-4 cursor-pointer'>DEFI GEM 2024</p>
            <a target='_blank' rel='noreferrer'></a>
            <p className='text-center py-4 cursor-pointer' >disclaimers</p>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer